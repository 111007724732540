import React, { useContext } from 'react';
import Loader from '../components/Loader';
import InitialTransition from '../components/InitialTransition';
import Header from '../components/Header';
import Copy from '../components/Copy';
import { AppContext } from '../context/AppContextProvider';

const Main = ({children}) => {
    const {loading, transition} = useContext(AppContext);

    return (
        <main className="Main">
            {loading ? <Loader /> : ""}
            {transition ? <InitialTransition /> : ""}
            <Header />
            {children}
            <Copy />
        </main>
    );
}

export default Main;