import React,{ Suspense, lazy} from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { AppContextProvider } from '../context/AppContextProvider';
import Main from '../container/Main';
import ScrollToTop from '../helpers/ScrollToTop';

const LandingPage = lazy(() =>  import('../pages/LandingPage'));
const NewRoute = lazy(() => import('../pages/NewRoute'));
const Program = lazy(() => import('../pages/Program'));
const Cost = lazy(() => import('../pages/Cost'));

const App = () => {
    return (
        <AppContextProvider>
            <Suspense fallback={<></>}>
                <BrowserRouter>
                    <ScrollToTop />
                    <AnimatePresence mode='wait'>
                        <Main>
                            <Routes>
                                <Route exact path='/' element={<LandingPage />}/>
                                <Route exact path='/nueva-ruta' element={<NewRoute />}/>
                                <Route exact path='/programa' element={<Program />}/>
                                <Route exact path='/inscripcion' element={<Cost />}/>
                                <Route path='*' element={""}/>
                            </Routes>
                        </Main>
                    </AnimatePresence>
                </BrowserRouter>
            </Suspense>
        </AppContextProvider>
    );
}

export default App;