import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Link as LinkScroll } from 'react-scroll';
import logoJadZO from '../assets/icons/Logo Jad ZO.svg';
import menuIcon from '../assets/icons/Menu.svg';
import logoSideBarNR from '../assets/icons/Logo NR.svg';
import '../styles/Header.css';
import { AppContext } from '../context/AppContextProvider';

const Header = () => {
    const {menu, setMenu, changeColor, setChangeColor, setTransition} = useContext(AppContext);
    const handleMenu = () => {
        setMenu(!menu);
    }
    const handleTransition = () => {
        setTransition(true);
    }
    const changeNavbarColor = () =>{
        if(window.scrollY >= 70){
          setChangeColor(true);
        }
        else{
          setChangeColor(false);
        }
    };
    window.addEventListener('scroll', changeNavbarColor);

    return (
        <header className={changeColor ? "colorchange" : ""}> 
            <img src={logoJadZO} alt="logo" className='logoJadZo' />
            <span className="menu-btnOpen">
                <img 
                    src={menuIcon} 
                    alt="menu icon"
                    onClick={handleMenu} 
                />
            </span>
            <nav className={`nav-bar ${menu && 'nav-bar__active'}`}>
                <ul className="sections-list">
                    <li id='#home'>
                        <LinkScroll 
                                activeClass='active' 
                                to='home' 
                                spy={true} 
                                smooth={true} 
                                offset={0} 
                                duration={400}
                                onClick={() => {
                                    handleMenu();
                                    handleTransition(); 
                                }}
                        >
                                <Link to="/">
                                    Inicio
                                </Link>
                        </LinkScroll>
                    </li>
                    <li id='#new-route'>
                    <LinkScroll 
                                activeClass='active' 
                                to='new-route' 
                                spy={true} 
                                smooth={true} 
                                offset={0} 
                                duration={400}
                                onClick={() => {
                                    handleMenu();
                                    handleTransition(); 
                                }}
                        >
                                <Link to="/nueva-ruta">
                                    Nueva Ruta
                                </Link>
                        </LinkScroll>
                    </li>
                    <li id='#program'>
                        <LinkScroll 
                                activeClass='active' 
                                to='program' 
                                spy={true} 
                                smooth={true} 
                                offset={0} 
                                duration={400}
                                onClick={() => {handleMenu(); handleTransition();}}
                        >
                                <Link to="/programa">
                                    Programa
                                </Link>
                        </LinkScroll>
                    </li>
                    <li id='#place'>
                        <LinkScroll 
                                activeClass='active' 
                                to='lugar' 
                                spy={true} 
                                smooth={true} 
                                offset={0} 
                                duration={400}
                                onClick={() => {handleMenu(); handleTransition();}}
                        >
                                <Link to="/">
                                    Lugar
                                </Link>
                        </LinkScroll>
                    </li>
                    <li id='#cost'>
                        <LinkScroll 
                                activeClass='active' 
                                to='cost' 
                                spy={true} 
                                smooth={true} 
                                offset={0} 
                                duration={400}
                                onClick={() => {handleMenu(); handleTransition();}}
                        >
                                <Link to="/inscripcion" onClick={() => {handleMenu(); handleTransition();}}>
                                    Inscripción
                                </Link>
                        </LinkScroll>
                    </li>
                    <li id='#frequent-questions'>
                        <LinkScroll 
                                activeClass='active' 
                                to='frecuents-questions' 
                                spy={true} 
                                smooth={true} 
                                offset={0} 
                                duration={400}
                                onClick={() => {handleMenu(); handleTransition();}}
                        >
                                <Link to="/">
                                    Preguntas Frecuentes
                                </Link>
                        </LinkScroll>
                    </li>
                </ul>
                <img src={logoSideBarNR} alt="logo NR" className='logoSideBarNR' />
            </nav>
        </header>
    );
}

export default Header;