import React, { useContext } from 'react';
import LogoLoader from '../assets/icons/Logo Jad ZO.svg';
import { AppContext } from '../context/AppContextProvider';

import '../styles/Loader.css';

const Loader = () => {
    const { loading, setLoading } = useContext(AppContext);
    return (
        <div className={loading ? "Loader" : "loaderOff"}
        onAnimationEnd={() => setLoading(false)}
        >
            <div className="logo-loader">
                <img src={LogoLoader} alt="Logo Loader" className='loader' />
            </div>
        </div>
    );
}

export default Loader;