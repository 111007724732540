import React from 'react';
import '../styles/Copy.css';

const Copy = () => {
    return (
        <div className="copy">
            <p>Copyright © 2023 - Todos los derechos reservados</p>
        </div>
    );
}

export default Copy;